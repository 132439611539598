import React from "react";
import { Helmet } from "react-helmet";

import Ausyris from "./Ausyris/index";
import OurExpertise from "./OurExpertise";
import DiscussWithUs from "./DiscussWithUs";
import MechanicalAndHydraulic from "./MechanicalAndHydraulic";
import MeasurementAndAnalysis from "./MeasurementAndAnalysis";
import ElectronicsAndElectrotechnics from "./ElectronicsAndElectrotechnics";
import IndustrialAutomationAndIT from "./IndustrialAutomationAndIT";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

export default function Home() {

  return (
    <>
      <Helmet>
        <title>AUSYRIS - Conception et réalisation de machines spéciales</title>
      </Helmet>
      <Ausyris />
      <OurExpertise />
      <DiscussWithUs />

      <MechanicalAndHydraulic />
      <IndustrialAutomationAndIT />
      <ElectronicsAndElectrotechnics />
      <MeasurementAndAnalysis />

      <AboutUs />
      <ContactUs />
    </>
  );
}
