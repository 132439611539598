import React from "react";
import PropTypes from "prop-types";

export default function TableTemplate({ data, columns }) {
  return (
    <div className="table-container">
      <table className="table is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr className="is-size-7">
            {columns.map((column) => (
              <th key={column.header} className={column.hidden_touch ? "is-hidden-touch" : ""}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tfoot>
          <tr className="is-size-7">
            {columns.map((column) => (
              <th key={column.header} className={column.hidden_touch ? "is-hidden-touch" : ""}>
                {column.header}
              </th>
            ))}
          </tr>
        </tfoot>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="is-size-7">
              {columns.map((column) => (
                <td key={column.key} className={column.hidden_touch ? "is-hidden-touch" : ""}>{column.render ? column.render(item) : item[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TableTemplate.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

TableTemplate.defaultProps = {
  data: [],
  columns: [],
};