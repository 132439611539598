import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import "./style.scss";

const Mailto = ({ email, fullName, messageText, society = "", phoneNumber = "", children }) => {
  let params = fullName || messageText ? "?" : "";
  let href = "javascript:void(0);";
  let disabled_status = "";
  if (fullName) params += `subject=${encodeURIComponent(fullName)}`;
  if (society) params += `${"\n Société: "}${encodeURIComponent(society)}`;
  if (phoneNumber) params += `${"\n Téléphone: "}${encodeURIComponent(phoneNumber)}`;
  if (messageText) params += `${fullName ? "&" : ""}body=${encodeURIComponent(messageText)}`;
  if (email) params += `${"\n Email: "}${encodeURIComponent(email)}`;
  if (email && fullName && messageText) {
    href = `${"mailto:info@ausyris.com"}${params}`;
  } else {
    disabled_status = "disabled";
  }


  return <a disabled={disabled_status} className="button is-primary is-outlined" href={href}>{children}</a>;
};

export default function ContactForm(props) {

  const [currentMessage, setCurrentMessage] = useState({
    fullName: props.fullName,
    phoneNumber: props.phoneNumber,
    email: props.email,
    society: props.society,
    message: props.messageText,
  });

  return (
    <>
      <div className="section">
        <Formik
          initialValues={{
            fullName: "",
            phoneNumber: "",
            email: "",
            society: "",
            message: "",
          }}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ errors, touched }) => (
            <Form>
            <div className="columns is-multiline">
              <div className="column is-full-mobile is-half">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-expanded">
                      <div className="field has-addons">
                        <p className="control">
                          <label className="button is-static is-small contact-us" htmlFor="fullName">&nbsp;Nom*</label>
                        </p>
                        <p className="control is-expanded">
                          <Field
                            className="input is-small"
                            placeholder="Votre nom et prénom"
                            type="text"
                            id="fullName"
                            name="fullName"
                            onChange={(e) => setCurrentMessage({ ...currentMessage, fullName: e.target.value })}
                            value={currentMessage.fullName}
                            required
                            />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-expanded">
                      <div className="field has-addons">
                        <p className="control">
                          <label className="button is-static is-small contact-us" htmlFor="email">&nbsp;Email*</label>
                        </p>
                        <p className="control is-expanded">
                          <Field
                            className="input is-small"
                            type="text"
                            placeholder="Votre adresse electronique"
                            id="email"
                            name="email"
                            onChange={(e) => setCurrentMessage({ ...currentMessage, email: e.target.value })}
                            value={currentMessage.email}
                            required
                            />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-expanded">
                      <div className="field has-addons">
                        <p className="control">
                          <label className="button is-static is-small contact-us" htmlFor="phoneNumber">&nbsp;Téléphone</label>
                        </p>
                        <p className="control is-expanded">
                          <Field
                            className="input is-small"
                            type="tel"
                            placeholder="Votre numéro de téléphone"
                            id="phoneNumber"
                            name="phoneNumber"
                            pattern="\d{10,10}"
                            onChange={(e) => setCurrentMessage({ ...currentMessage, phoneNumber: e.target.value })}
                            value={currentMessage.phoneNumber}
                            />
                            {errors.phoneNumber && touched.phoneNumber ? (
                              <p className="help is-danger">{errors.phoneNumber}</p>
                            ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-half">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-expanded">
                      <div className="field has-addons">
                        <p className="control">
                          <label className="button is-static is-small contact-us" htmlFor="society">&nbsp;Société</label>
                        </p>
                        <p className="control is-expanded">
                          <Field
                            className="input is-small"
                            type="text"
                            id="society"
                            name="society"
                            placeholder="Le nom de votre société"
                            onChange={(e) => setCurrentMessage({ ...currentMessage, society: e.target.value })}
                            value={currentMessage.society}
                            />
                            {errors.society && touched.society ? (
                              <p className="help is-danger">{errors.society}</p>
                            ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column is-full-mobile is-full">
                  <div className="field-label"><label className="" htmlFor="messageText">&nbsp;Votre message</label></div>
                <textarea
                  className="textarea is-small"
                  placeholder="Votre message..."
                  rows="8"
                  id="messageText"
                  name="messageText"
                  onChange={(e) => setCurrentMessage({ ...currentMessage, messageText: e.target.value })}
                  value={currentMessage.messageText}
                  ></textarea>
                  {errors.messageText && touched.messageText ? (
                    <p className="help is-danger">{errors.messageText}</p>
                  ) : null}
              </div>
            </div>

              <div className="block justify-content-center">
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input type="checkbox" />
                      &nbsp;En cochant cette case, j&apos;accepte la Politique de confidentialité de ce site
                    </label>
                  </div>
                </div>
              </div>
              <div className="field is-grouped contact-us-grouped">
                <div className="control">
                  <Mailto
                    fullName={currentMessage.fullName}
                    email={currentMessage.email}
                    society={currentMessage.society}
                    messageText={currentMessage.messageText}
                    phoneNumber={currentMessage.phoneNumber}
                  >
                    Envoyer le message
                  </Mailto>
                </div>
              </div>

              <div className="block justify-content-center">
                <div className="content">
                  * : Champs obligatoires

                  {errors.fullName && touched.fullName ? (
                    <p className="help is-danger">{errors.fullName}</p>
                  ) : null}
                  {errors.email && touched.email ? (
                    <p className="help is-danger">{errors.email}</p>
                  ) : null}

                </div>
              </div>
          </Form>

          )}
        </Formik>
      </div>
    </>
  );
}

ContactForm.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  society: PropTypes.string,
  messageText: PropTypes.string
};

Mailto.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  society: PropTypes.string,
  messageText: PropTypes.string,
  children: PropTypes.string
};