import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { ReactSVG } from "react-svg";

export default function Card(props) {
  return (
    <div className="card has-background-light">
      <div className="card-image">
        <div className="pt-6"></div>
        <figure className="image is-128x128">
          <ReactSVG src={props.image.src} alt={props.image.alt} className="" />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title has-text-info is-size-7-mobile is-size-7-tablet is-capitalized">
              {props.title}
              <br />
              {props.subtltle}
            </p>
          </div>
        </div>
      </div>

      <footer className="card-footer has-background-very-light">
        <a href={props.link} className="card-footer-item has-text-weight-bold has-text-grey-light is-size-7-mobile is-size-7-tablet is-uppercase">{props.linkTitle}</a>
      </footer>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  subtltle: PropTypes.string,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.image,
    alt: PropTypes.number
  })
};