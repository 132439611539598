import React from "react";

import imageBan from "./../../assets/ausyris-logo.png";
import "./style.css";

export default function ContactMap() {

  const mapContact = (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          title="Map"
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=1%20Rue%20Jacqueline%20Auriol,%2078280%20GUYANCOURT&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
        <a href="https://fmovies-online.net"></a>
        <br />
      </div>
    </div>
  );

  return (
    <div className="columns">
      <div className="column is-half has-background-link">
        <section className="hero is-fullheight is-success">
          <div className="hero-body is-justify-content-center is-centered">
            <figure className="is-justify-content-center is-centered">
              <img src={imageBan} alt="AUSYRIS" className="" />
            </figure>
          </div>
        </section>
      </div>
      <div className="column">
        <section className="hero is-fullheight">
          <div className="hero-body">{mapContact}</div>
        </section>
      </div>
    </div>
  );
}
