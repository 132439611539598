import React from "react";
import VCard from "vcard-creator";
import { Helmet } from "react-helmet";
import {
  FaPaperPlane,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaBriefcase,
  FaGlobe,
  FaUserPlus,
} from "react-icons/fa";

import logo from "./../../assets/ausyris-logo-original.webp";
import "./style.scss";

export default function Gbd() {
  const gbd = new VCard();
  gbd
    .addPhoto(logo, "JPEG")
    .addName("PEJOUX", "Jérémy", "", "", "")
    .addCompany("AUSYRIS")
    .addJobtitle("Directeur Général")
    .addRole("Responsable Commercial")
    .addEmail("jeremy.pejoux@ausyris.com")
    .addPhoneNumber("+33617746776", "PREF;WORK")
    .addPhoneNumber("+33617746776", "WORK")
    .addAddress(
      "",
      "",
      "57 rue Ernest Renan",
      "Nanterre",
      "",
      "92000",
      "France"
    )
    .addURL("https://www.ausyris.com");

  const jpxFile = new Blob([gbd.toString()], {
    type: "text/vcard;charset=ISO-8859-1",
  });

  return (
    <>
      <Helmet>
        <title>Jérémy PEJOUX Directeur Général chez AUSYRIS</title>
      </Helmet>

      <div className="has-text-centered card-animation">
        <div className="columns is-centered is-vcentered">
          <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-half-widescreen is-two-third-fullhd">
            <div className="card bg-bleu-nuit-color">
              <div className="card-image bg-bleu-nuit-color card-bg-image">
                <div className="columns is-desktop is-centered is-vcentered">
                  <div className="column is-full-mobile is-narrow">
                    <a
                      href="/"
                      className=""
                    >
                      <figure className="image is-128x128 is-centered is-vcentered has-text-centered">
                        <img className="" src={logo} alt="AUSYRIS" />
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-content bg-bleu-nuit-color">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4 has-text-white">Jérémy PEJOUX</p>
                  </div>
                </div>
              </div>

              <footer className="card-footer">
                <a
                  href="tel:+33617746776"
                  className="card-footer-item card-footer-item-vcard is-uppercase has-text-white-bis"
                >
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <span className="">Appeler</span>
                </a>

                <a
                  className="card-footer-item card-footer-item-vcard is-uppercase has-text-white-bis"
                  id="email-jeremy-pejoux"
                  href="mailto:jeremy.pejoux@ausyris.com"
                >
                  <span className="icon">
                    <FaPaperPlane />
                  </span>
                  <span className="">E-mail</span>
                </a>

                <a
                  href="https://maps.app.goo.gl/SnyxMKMivwGLj4ib6"
                  className="card-footer-item card-footer-item-vcard is-uppercase has-text-white-bis"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon">
                    <FaMapMarkerAlt />
                  </span>
                  <span className="">Itinéraire</span>
                </a>
              </footer>

              <div className="card-content has-background-white">
                <div className="media is-vcentered">
                  <div className="media-left is-vcentered">
                    <span className="icon is-size-2">
                      <FaPhoneAlt />
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-6 has-text-left has-text-grey-dark">
                      <a
                        className="has-text-grey-dark"
                        id="e-amil"
                        href="tel:+33617746776"
                      >
                        +33 (0)6 17 74 67 76
                      </a>
                    </p>
                    <p className="subtitle is-6 has-text-left has-text-grey-light">
                      Mobile
                    </p>
                  </div>
                </div>
                <div className="media is-vcentered">
                  <div className="media-left is-vcentered">
                    <span className="icon is-size-2">
                      <FaEnvelope />
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-6 has-text-left has-text-grey-dark">
                      <a
                        className="has-text-grey-dark"
                        id="e-amil"
                        href="mailto:jeremy.pejoux@ausyris.com"
                      >
                        jeremy.pejoux@ausyris.com
                      </a>
                    </p>
                    <p className="subtitle is-6 has-text-left has-text-grey-light">
                      E-mail
                    </p>
                  </div>
                </div>
                <div className="media is-vcentered">
                  <div className="media-left is-vcentered">
                    <span className="icon is-size-2">
                      <FaBriefcase />
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-6 has-text-left has-text-grey-dark">
                      Responsable Commercial
                    </p>
                    <p className="subtitle is-6 has-text-left has-text-grey-light">
                      Directeur Général
                    </p>
                  </div>
                </div>
                <div className="media is-vcentered">
                  <div className="media-left is-vcentered">
                    <span className="icon is-size-2">
                      <FaMapMarkerAlt />
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-6 has-text-left has-text-grey-dark">
                      57 rue Ernest Renan Nanterre, IDF 92000
                    </p>
                    <p className="subtitle is-6 has-text-left has-text-grey-light">
                      France
                    </p>

                    <p className="subtitle is-6 has-text-left">
                      <a
                        href="https://maps.app.goo.gl/SnyxMKMivwGLj4ib6"
                        className="card-footer-item is-uppercase jaune-citron-color has-text-left"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="is-uppercase jaune-citron-color has-text-left">
                          Afficher sur la carte
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="media is-vcentered">
                  <div className="media-left is-vcentered">
                    <span className="icon is-size-2">
                      <FaGlobe />
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-6 has-text-left has-text-grey-dark">
                      <a
                        className="has-text-grey-dark"
                        id="e-amil"
                        href="https://www.ausyris.com"
                      >
                        www.ausyris.com
                      </a>
                    </p>
                    <p className="subtitle is-6 has-text-left has-text-grey-light">
                      Site web
                    </p>
                  </div>
                </div>
                <div className="media is-vcentered is-centered is-vcentered hast-text-centered">
                  <div className="media-content is-centered is-vcentered hast-text-centered">
                    <div className="is-centered is-vcentered hast-text-centered">
                      <a
                        className="button is-uppercase has-text-white-bis bg-jaune-citron-color"
                        id="download"
                        download="Jérémy PEJOUX.vcf"
                        href={URL.createObjectURL(jpxFile)}
                      >
                        <span className="icon">
                          <FaUserPlus />
                        </span>
                        <span className="is-size-6 uppercase has-text-white-bis">
                          Enregistrer
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
