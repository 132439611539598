import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOneToCart, removeFromCartItem, plusOne, removeOne } from "../../features/cart";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTrashAlt, faCartPlus } from "@fortawesome/free-solid-svg-icons";

export default function ItemActions({item}) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const isItemInCart = (itemId) => {
    return cartItems.some((cartItem) => cartItem.id === itemId);
  };

  return (
    <div className="buttons align-items align-content are-small">
      {isItemInCart(item.id) ? (
        <>
          {
            (cartItems.find((cartItem) => cartItem.id === item.id)?.quantite == 1) && (
              <>
                <button
                  className="button is-danger is-light is-small is-outlined"
                  onClick={() => dispatch(removeFromCartItem(item))}

                  disabled={cartItems.find(cartItem => cartItem.id === item.id)?.quantite !== 1}
                >
                  <span title="Supprimer du panier">
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </button>
              </>
            )
          }
          {
            (cartItems.find((cartItem) => cartItem.id === item.id)?.quantite > 1) && (
              <>
                <button
                  title="Supprimer un du panier" className="button is-small" onClick={() => dispatch(removeOne(item.id))} disabled={cartItems.find(cartItem => cartItem.id === item.id)?.quantite <= 1}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </>
            )
          }          

          <button disabled className="button is-success is-small">{cartItems.find(cartItem => cartItem.id === item.id)?.quantite || 0}</button>

          <button
            title="Ajouter un dans le panier"
            className="button is-small"
            onClick={() => dispatch(plusOne(item.id))}
            disabled={cartItems.find((cartItem) => cartItem.id === item.id)?.quantite >= item.quantityMax}
          >
            <FontAwesomeIcon icon={faPlus} /> 
          </button>
        </>
      ) : (
        <button
          className="button is-primary is-small"
          onClick={() => dispatch(addOneToCart(item.id))}
        >
          <span title="Ajouter au Panier">
            <FontAwesomeIcon icon={faCartPlus} />
          </span>
        </button>
      )}
    </div>
  );
}

ItemActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantityMax: PropTypes.number.isRequired,
  }).isRequired,
};