import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ItemActions from "./ItemActions";
import TagsTemplate from "../Templates/TagsTemplate";
import "./product.scss";


import {
  addOneToCart,
  deleteFromCart,
} from "../../features/cart";

import { getProductByProductId } from "../../features/product";
import "./product.scss";

import ProductFooter from "./../Templates/ProductFooter";
import ProductHeader from "./../Templates/ProductHeader";
import ProductTemplate from "./../Templates/ProductTemplate";

export default function Product() {
  const { id } = useParams();
  const [productId, setProductIdhowTable] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductComponents, setselectedProductComponents] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fournisseurSearchTerm, setFournisseurSearchTerm] = useState("");
  const [showFournisseurFilter, setShowFournisseurFilter] = useState(true);

  const product = useSelector((state) => state.product);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const isItemInCart = (itemId) => {
    return cartItems.some((cartItem) => cartItem.id === itemId);
  };

  const dispatch = useDispatch();

  if (!product.productComponents) {
    dispatch(getProductByProductId(productId));
  }

  const originalProductComponents = product.productComponents || [];

  const productComponents = originalProductComponents.map((item) => ({
    id: item.component.id,
    name: item.component.name,
    label: item.component.label,
    description: item.component.description ? item.component.description.substring(0, 40) + "..." : "",
    designation_repere: item.component.landmark,
    fabricant: item.component.maker,
    reference_fabricant: item.component.maker_reference,
    fournisseur: item.component.supplier,
    reference_fournisseur: item.component.supplier_reference,
    quantite: item.component.quantity,
    picked: item.component.picked,
    category: item.component.category,
    quantityMin: item.quantity_min,
    quantityMax: 1000,
  }));

  const [showedProducts, setShowedProducts] = useState(productComponents);

  function handleShowTable() {
    setShowTable(true);
    setShowGrid(false);
  }

  function handleShowGrid() {
    setShowTable(false);
    setShowGrid(true);
  }

  const filteredProducts = (productComponents || []).filter((item) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      item.description?.toLowerCase().includes(searchTermLower) ||
      item.designation_repere?.toLowerCase().includes(searchTermLower) ||
      item.fabricant?.toLowerCase().includes(searchTermLower) ||
      item.reference_fabricant?.toLowerCase().includes(searchTermLower) ||
      item.fournisseur?.toLowerCase().includes(searchTermLower) ||
      item.reference_fournisseur?.toLowerCase().includes(searchTermLower)
    );
  });

  // Filter by category
  const categoryFilteredProducts =
    selectedCategories.length > 0
      ? filteredProducts.filter((item) =>
        selectedCategories.includes(item.category)
      )
      : filteredProducts;

  // Filter by fournisseurs (multiple selection)
  const designationFilteredProducts =
    selectedDesignations.length > 0
      ? categoryFilteredProducts.filter((item) =>
        selectedDesignations.includes(item.designation_repere)
      )
      : categoryFilteredProducts;

  // Filter by fournisseurs (multiple selection)
  const fournisseurFilteredProducts =
    selectedFournisseurs.length > 0
      ? designationFilteredProducts.filter((item) =>
        selectedFournisseurs.includes(item.fournisseur)
      )
      : designationFilteredProducts;

  const handleCheckboxChange = (id) => {
    let newselectedProductComponents = [...selectedProductComponents];

    if (id === "") {
      newselectedProductComponents = [];
    } else if (id === "all") {
      if (newselectedProductComponents.length === fournisseurFilteredProducts.length) {
        newselectedProductComponents = [];
      } else {
        newselectedProductComponents = fournisseurFilteredProducts.map(
          (item) => item.id
        );
      }
    } else {
      const index = newselectedProductComponents.indexOf(id);
      if (index === -1) {
        newselectedProductComponents.push(id);
      } else {
        newselectedProductComponents.splice(index, 1);
      }
    }

    setselectedProductComponents(newselectedProductComponents);
  };

   const handleDeleteSelected = () => {
    selectedProductComponents.map((item) => dispatch(deleteFromCart(item)));
    setselectedProductComponents([]);
  };

  const handleAddSelectedToCart = () => {
    selectedProductComponents.map((item) => dispatch(addOneToCart(item)));
    setselectedProductComponents([]);
  }; 

  const uniqueFournisseurs = [
    ...new Set((productComponents || []).map((item) => item.fournisseur)),
  ];

  // Filter uniqueFournisseurs to remove selectedFournisseurs
  const availableFournisseurs = uniqueFournisseurs.filter(
    (fournisseur) => !selectedFournisseurs.includes(fournisseur)
  );

  const handleFournisseurChange = (event) => {
    const fournisseur = event.target.value;
    const newSelectedFournisseurs = [...selectedFournisseurs];

    const index = newSelectedFournisseurs.indexOf(fournisseur);
    if (index === -1) {
      newSelectedFournisseurs.push(fournisseur);
    } else {
      newSelectedFournisseurs.splice(index, 1);
    }

    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const handleDeleteFournisseur = (fournisseurToRemove) => {
    const newSelectedFournisseurs = selectedFournisseurs.filter(
      (fournisseur) => fournisseur !== fournisseurToRemove
    );
    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const resetFournisseurFilters = () => {
    setSelectedFournisseurs([]);
  };

  const handleFournisseurClick = (fournisseur) => {
    if (selectedFournisseurs.includes(fournisseur)) {
      setSelectedFournisseurs(
        selectedFournisseurs.filter((f) => f !== fournisseur)
      );
    } else {
      setSelectedFournisseurs([...selectedFournisseurs, fournisseur]);
    }
  };

  const columns = [
    {
      header: "",
      key: "checkbox",
      render: (item) => (
        <input
          type="checkbox"
          checked={selectedProductComponents.includes(item.id)}
          onChange={() => handleCheckboxChange(item.id)}
        />
      ),
    },
    {
      header: "Designation / Repere",
      key: "designation_repere", hidden_touch: true, class_name: "",
      render: (item) =>
        item.designation_repere === "NULL"
          ? "Aucun"
          : item.designation_repere,
    },
    { header: "Description", key: "description", hidden_touch: true, class_name:"" },
    { header: "Fabricant", key: "fabricant", hidden_touch: true, class_name:"" },
    { header: "Réf.Fabricant", key: "reference_fabricant", class_name:"" },
    { header: "Fournisseur", key: "fournisseur", hidden_touch: true, class_name:"" },
    { header: "Réf.Fournisseur", key: "reference_fournisseur", class_name:"" },
    { header: "Quantité installée", key: "quantite", hidden_touch: true, class_name:"" },
    {
      header: "Quantité Panier",
      key: "actions",
      render: (item) => <ItemActions item={item} />,
    },
  ];

  const findItemById = (productId) => {
    return fournisseurFilteredProducts.find((item) => item.id === productId);
  };

  const totalSelectedComponentsQuantity = selectedProductComponents.reduce(
    (total, productId) => {
      const item = productComponents.find(
        (item) => item.id === productId
      );
      return total + (item ? item.quantite : 0);
    },
    0
  );

  const totalSelectedComponents = selectedProductComponents.reduce((total, productId) => {
    return total + 1;
  }, 0);

  const totalQuantity = productComponents.reduce(
    (total, item) => total + item.quantite,
    0
  );
  const totalResultComposants = fournisseurFilteredProducts.length;
  const totalProductComponents = productComponents.length;

  const filteredUniqueFournisseurs = uniqueFournisseurs.filter((fournisseur) => {
    if (fournisseur) {
      return fournisseur.toLowerCase().includes(fournisseurSearchTerm.toLowerCase());
    }
    return false;
  });

  const handleTagClick = (tag) => {
    console.log("Clicked tag : "+tag);
  };

  const headerTags = {};

  if (totalSelectedComponents > 0) {
    var title = `${totalSelectedComponents} composant${totalSelectedComponents > 1 ? "s" : ""
      } sélectionné${totalSelectedComponents > 1 ? "s" : ""}`;
    headerTags["totalSelectedComponents"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  if (totalSelectedComponentsQuantity > 0) {
    headerTags["totalSelectedComponentsQuantity"] = {
      title: `${totalSelectedComponentsQuantity} pièce${totalSelectedComponentsQuantity > 1 ? "s" : ""
        } séléctionnée${totalSelectedComponentsQuantity > 1 ? "s" : ""}`,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  return (
    <>
      <section className="section">
        <h1 className="title">{product.productName}</h1>
        <h2 className="subtitle">
          {product.businessReference} | {product.clientName} |{" "}
          {product.yearOfCreation}
        </h2>
      </section>

      <section className="section has-background-light">
        <button className="button" onClick={() => setShowFournisseurFilter(!showFournisseurFilter)}>
          {showFournisseurFilter ? "Masquer" : "Afficher"} le filtre fournisseur
        </button>

        <div className="tags">
          <span className="tag is-white is-light is-large is-size-6-mobile">
            {totalProductComponents} Composants
          </span>
          <span className="tag is-white is-light is-large is-size-6-mobile">
            {totalQuantity} Pièces installées
          </span>
        </div>
        {/* SEARCH FILTERS */}
        {showFournisseurFilter && ( 
        <div className="columns is-multiline">
          <div className="column is-one-third-desktop is-one-third-tablet is-full-mobile">
            <nav className="panel has-background-white">
              <p className="panel-heading has-background-white is-size-5 is-size-6-mobile">Sélectionner un fournisseur</p>
              <div className="panel-block">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Recherche un fournisseur"
                    value={fournisseurSearchTerm}
                    onChange={(e) => setFournisseurSearchTerm(e.target.value)}
                  />
                </p>
              </div>
              {filteredUniqueFournisseurs.length > 0 && (
                <div>
                  <div className="select is-multiple is-fullwidth">
                    <select
                      multiple
                      value={selectedFournisseurs}
                      onChange={handleFournisseurChange}
                      size="6"
                    >
                      {filteredUniqueFournisseurs.map((fournisseur) => (
                        <option
                          key={`filtered_${fournisseur}`}
                          value={fournisseur}
                        >
                          {fournisseur}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="panel-block">
                <button
                  className="button is-outlined is-fullwidth"
                  onClick={resetFournisseurFilters} // Call the reset function here
                >
                  Réinitialiser le filtre
                </button>
              </div>
            </nav>
          </div>
        </div>
        )}
      </section>

      <section className="section has-background-white">
        <div className="columns is-multiline">
          <ProductHeader
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            totalResultComposants={totalResultComposants}
            totalProductComponents={totalProductComponents}
            selectedProductComponents={selectedProductComponents}
            handleDeleteSelected={handleDeleteSelected}
            handleAddSelectedToCart={handleAddSelectedToCart}
            handleCheckboxChange={handleCheckboxChange}
            totalSelectedComponents={totalSelectedComponents}
            totalSelectedComponentsQuantity={totalSelectedComponentsQuantity}
          />
         

         <div className="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            {selectedFournisseurs.length > 0 ? (
              <>
                <h4 className="subtitle is-pulled-left is-size-4 pr-3">
                  Filtres appliqués :
                </h4>
                <div className="field is-grouped is-grouped-multiline">
                  {selectedFournisseurs.map((fournisseur) => (
                    <div className="control" key={`selected_${fournisseur}`}>
                      <div className="tags has-addons">
                        <a
                          className="tag is-primary is-clickable"
                          onClick={() => handleDeleteFournisseur(fournisseur)}
                        >
                          {fournisseur}
                        </a>
                        <a
                          className="tag is-delete is-clickable"
                          onClick={() => handleDeleteFournisseur(fournisseur)}
                        ></a>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {/* <h4 className="subtitle is-pulled-left is-size-4 pr-3 has-text-grey">
                  <em>Aucun filtre appliqué.</em>
                </h4> */}
              </>
            )}
          </div>
        </div>
      </section>

      <ProductTemplate
        showTable={showTable}
        fournisseurFilteredProducts={fournisseurFilteredProducts}
        columns={columns}
        selectedProductComponents={selectedProductComponents}
        handleCheckboxChange={handleCheckboxChange}
        handleAddSelectedToCart={handleAddSelectedToCart}
        totalResultComposants={totalResultComposants}
        handleDeleteSelected={handleDeleteSelected}
      />
    </>
  );
}
